import worldview from "../assets/images/worldview-screenshot.jpg";
import arcticai from "../assets/images/arcticai.jpg";

export const projectsData = [
  {
    id: 1,
    projectName: "NASA Worldview",
    projectDesc: `An interactive interface that provides real-time access to global satellite imagery and data, supporting a broad range of
    scientific research and public awareness efforts. It enables users to explore and visualize layers of geophysical
    parameters, historical data, and custom configurations for a comprehensive understanding of Earth's dynamic systems.
    The front end of Worldview is completely open source so please feel free to check out my contributions on GitHub.`,
    tags: ["JavaScript, Python, React, AWS, OpenLayers"],
    code: "https://github.com/nasa-gibs/worldview",
    demo: "https://worldview.earthdata.nasa.gov/",
    image: worldview,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
