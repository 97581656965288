import eic from "../assets/images/eic.jpg";
import ddv from "../assets/images/ddv.jpg";
import cloudPractitioner from "../assets/images/cloud_practitioner.png";
import solutionsArchitect from "../assets/images/solutions_architect.png";

export const achievementData = {
  bio: "",
  achievements: [
    {
      id: 0,
      title: "AWS Certified Solutions Architect - Associate",
      details: "Validation Number: YRWW17RJY2VEQY9F",
      date: "2023",
      field: "Cloud Computing",
      image: solutionsArchitect,
    },
    {
      id: 1,
      title: "AWS Certified Cloud Practitioner",
      details: "Validation Number: 511R5MK1VM1Q1MWE",
      date: "2023",
      field: "Cloud Computing",
      image: cloudPractitioner,
    },
    {
      id: 2,
      title: "EIC Mode for NASA Worldview",
      details:
        'Developed and implemented "EIC Mode" for NASAs flagship Worldview project, currently showcased at NASAs Earth Information Headquarters in Washington, D.C. Scheduled for inclusion in a 2024 exhibit at the Smithsonian Natural History Museum. The feature automates real-time satellite imagery selection by leveraging custom algorithms to identify and display the highest-quality data available.',
      date: "2023",
      field: "Aerospace",
      image: eic,
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
