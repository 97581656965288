export const educationData = [
  {
    id: 1,
    institution: "University of Connecticut School of Engineering",
    course: "Certificate in Full Stack Development",
    startYear: "2021",
    endYear: "2022",
  },
  {
    id: 2,
    institution: "Central Connecticut State University",
    course:
      "B.A. Organizational Communication / Geographic Information Systems",
    startYear: "2012",
    endYear: "2015",
  },
];
