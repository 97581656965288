export const experienceData = [
  {
    id: 1,
    company: "Vantage Systems: NASA GIBS/Worldview",
    jobtitle: "Senior Full Stack Developer",
    startYear: "2022",
    endYear: "",
    description:
      "Civilian contractor for NASA Goddard Space Flight Center on the NASA GIBS & NASA Worldview teams. Lead full-stack development initiatives encompassing front-end technologies (React), back-end languages (Python &Node), and cloud-based solutions (AWS) for NASA's high-profile Worldview project and the GIBS back-end team",
  },
  {
    id: 2,
    company: "Aetna/CVS",
    jobtitle: "Senior Data Analyst",
    startYear: "2019",
    endYear: "2022",
    description:
      "Conducted comprehensive risk assessment analyses for the Underwriting Department, utilizing Python, SQL, Jupyter Notebooks, and Microsoft Excel. Specialized in evaluating premium and deductible usage as well as medical claims. Delivered actionable data visualizations to management and statistical models to support financial decision-making.",
  },
];
